import {BaseGraph} from "./base_graph";

class AppVersions extends BaseGraph {
    constructor() {
        super('app_versions');
    }

    postSetup($parent) {
        /*$parent.find('a').unbind('click').on('click', async (e) => {
            e.preventDefault();
            if(!app.DASHBOARD_CORE.GRID) {
                return;
            }

            try {
                //const layout = app.DASHBOARD_CORE.GRID.save();
                const layoutRes = await app.DASHBOARD_CORE.updateLayout();
            } catch {

            }
        });*/
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new AppVersions();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
